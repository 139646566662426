import styled from '@emotion/styled';

import * as Avatars from '@/assets/avatars';
import { Flex } from '@/elements/Div';
import { Organization, User } from '@/generated/upbound-graphql';

const imageArray = [
  Avatars.AvatarBowlsBlue,
  Avatars.AvatarBowlsOrange,
  Avatars.AvatarBowlsStrawberry,
  Avatars.AvatarBowlsTeal,
  Avatars.AvatarBowlsViolet,
  Avatars.AvatarDotBlue,
  Avatars.AvatarDotOrange,
  Avatars.AvatarDotStrawberry,
  Avatars.AvatarDotTeal,
  Avatars.AvatarDotViolet,
  Avatars.AvatarMarriedBlue,
  Avatars.AvatarMarriedOrange,
  Avatars.AvatarMarriedStrawberry,
  Avatars.AvatarMarriedTeal,
  Avatars.AvatarMarriedViolet,
  Avatars.AvatarRadarBlue,
  Avatars.AvatarRadarOrange,
  Avatars.AvatarRadarStrawberry,
  Avatars.AvatarRadarTeal,
  Avatars.AvatarRadarViolet,
  Avatars.AvatarSailBlue,
  Avatars.AvatarSailOrange,
  Avatars.AvatarSailStrawberry,
  Avatars.AvatarSailTeal,
  Avatars.AvatarSailViolet,
  Avatars.AvatarSpaceBlue,
  Avatars.AvatarSpaceOrange,
  Avatars.AvatarSpaceStrawberry,
  Avatars.AvatarSpaceTeal,
  Avatars.AvatarSpaceViolet,
];

const StyledAvatarPlaceholder = styled(Flex)<{ size: number }>`
  background-position: center;
  background-size: ${({ size }) => size}px;
  background-repeat: no-repeat;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
`;

const propCheck = (name1: string | undefined, name2: string | undefined, last = false) => {
  if (name1) {
    return name1;
  } else if (name2) {
    return name2.split(' ')[last ? 1 : 0];
  } else {
    return '';
  }
};

// From: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript#comment128649071_7616484
function computeHash(str: string) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) {
    return hash;
  }
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash &= 0xffff; // Convert to 32bit integer
  }
  return hash;
}

const getAvatarComponent = (val: string, resourceArray: string[]) => {
  const hash = computeHash(val);
  const count = resourceArray.length;
  if (count <= 1) {
    return resourceArray[0];
  }
  return resourceArray[hash % count];
};

export const AvatarPlaceholder: React.FC<{
  altText?: string;
  memberName?: string | null;
  organization?: Pick<Organization, 'id' | 'displayName'> | null;
  user?: Pick<User, 'username' | 'name' | 'email'> | null;
  size?: 24 | 32 | 48 | 72 | 92;
  type?: 'OrgAccount' | 'UserAccount' | 'orgMember' | 'teamMember';
}> = ({ altText = '', memberName = null, organization = null, size = 48, type = 'UserAccount', user = null }) => {
  let valueToHash = ' ';
  const username: string = type === 'OrgAccount' && user !== null ? propCheck(user.username, user.name) : '';
  const orgName: string =
    type === 'OrgAccount' && organization !== null ? propCheck(organization.displayName, undefined) : '';

  if (type === 'UserAccount' && user !== null && (username || user.email)) {
    valueToHash = username ? username.toUpperCase() : user.email && user.email.toUpperCase();
  } else if (type === 'OrgAccount' && organization !== null && orgName !== null) {
    valueToHash = orgName.toUpperCase();
  } else if (memberName !== null) {
    valueToHash = memberName.toUpperCase();
  }

  const avatarImage = getAvatarComponent(valueToHash.toLowerCase(), imageArray);

  return (
    <div title={altText}>
      <StyledAvatarPlaceholder size={size} backgroundImage={`url(${avatarImage})`} />
    </div>
  );
};
