export { default as AvatarBowlsBlue } from './Bowls-Blue.svg';
export { default as AvatarBowlsOrange } from './Bowls-Orange.svg';
export { default as AvatarBowlsStrawberry } from './Bowls-Strawberry.svg';
export { default as AvatarBowlsTeal } from './Bowls-Teal.svg';
export { default as AvatarBowlsViolet } from './Bowls-Violet.svg';
export { default as AvatarDotBlue } from './Dot-Blue.svg';
export { default as AvatarDotOrange } from './Dot-Orange.svg';
export { default as AvatarDotStrawberry } from './Dot-Strawberry.svg';
export { default as AvatarDotTeal } from './Dot-Teal.svg';
export { default as AvatarDotViolet } from './Dot-Violet.svg';
export { default as AvatarMarriedBlue } from './Married-Blue.svg';
export { default as AvatarMarriedOrange } from './Married-Orange.svg';
export { default as AvatarMarriedStrawberry } from './Married-Strawberry.svg';
export { default as AvatarMarriedTeal } from './Married-Teal.svg';
export { default as AvatarMarriedViolet } from './Married-Violet.svg';
export { default as AvatarRadarBlue } from './Radar-Blue.svg';
export { default as AvatarRadarOrange } from './Radar-Orange.svg';
export { default as AvatarRadarStrawberry } from './Radar-Strawberry.svg';
export { default as AvatarRadarTeal } from './Radar-Teal.svg';
export { default as AvatarRadarViolet } from './Radar-Violet.svg';
export { default as AvatarSailBlue } from './Sail-Blue.svg';
export { default as AvatarSailOrange } from './Sail-Orange.svg';
export { default as AvatarSailStrawberry } from './Sail-Strawberry.svg';
export { default as AvatarSailTeal } from './Sail-Teal.svg';
export { default as AvatarSailViolet } from './Sail-Violet.svg';
export { default as AvatarSpaceBlue } from './Space-Blue.svg';
export { default as AvatarSpaceOrange } from './Space-Orange.svg';
export { default as AvatarSpaceStrawberry } from './Space-Strawberry.svg';
export { default as AvatarSpaceTeal } from './Space-Teal.svg';
export { default as AvatarSpaceViolet } from './Space-Violet.svg';
