import { CONFIG_BASE_DOMAIN } from './config';

//#region Marketing

export const marketingHome = { url: () => 'https://upbound.io' };
export const marketingFaq = { url: () => 'https://upbound.io/faq' };
export const marketingPartners = { url: () => 'https://upbound.io/partners' };
export const marketingPrivacy = { url: () => 'https://upbound.io/privacy' };
export const marketingTerms = { url: () => 'https://upbound.io/terms' };
export const marketingIndividualTierTerms = { url: () => 'https://upbound.io/individual-terms' };
export const marketingTeamTierTerms = { url: () => 'https://upbound.io/team-terms' };
export const marketingProductPricing = { url: () => 'https://upbound.io/product/pricing' };

//#endregion

//#region Marketplace

export const marketplaceBrowse = {
  route: '/browse' as const,
  url: () => `https://marketplace.${CONFIG_BASE_DOMAIN}`,
};
export const marketplaceOrg = {
  route: '/browse' as const,
  url: (orgName: string) => `https://marketplace.${CONFIG_BASE_DOMAIN}/account/${encodeURIComponent(orgName)}`,
};

//#endregion

export const consoleApp = { url: () => `https://console.${CONFIG_BASE_DOMAIN}` };
export const consoleAppOrg = {
  url: (orgName: string) => `https://console.${CONFIG_BASE_DOMAIN}/${encodeURIComponent(orgName)}`,
};

//#region Crossplane

export const crossplaneDocs = { url: () => 'https://crossplane.io/docs' };
export const crossplaneSlack = { url: () => 'https://slack.crossplane.io' };

//#endregion

//#region MailTo

export const mailToSales = { url: () => 'mailto:sales@upbound.io' };
export const mailToInfo = { url: () => 'mailto:info@upbound.io' };

//#endregion

//#region Upbound

export const upboundBlog = { url: () => 'https://blog.upbound.io' };
export const upboundStatus = { url: () => 'https://status.upbound.io' };
export const upboundGreenhouse = { url: () => 'https://boards.greenhouse.io/upbound' };
export const upboundPrivatePreview = { url: () => 'https://www.upbound.io/upbound-preview' };
export const contactUs = { url: () => 'https://www.upbound.io/contact' };

//#endregion

//#region Docs

export const docsHome = { url: () => 'https://docs.upbound.io/' };
export const docsCli = { url: () => 'https://docs.upbound.io/cli' };
export const docsUxp = { url: () => 'https://docs.upbound.io/uxp' };
export const docsCloud = { url: () => 'https://docs.upbound.io/upbound-cloud' };
export const docsMarketplace = { url: () => 'https://docs.upbound.io/upbound-marketplace' };
export const docsPublishing = { url: () => 'https://docs.upbound.io/upbound-marketplace/publishing-a-listing' };
export const docsSso = { url: () => 'https://docs.upbound.io/accounts/authentication/enable-sso' };

//#endregion
